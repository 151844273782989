<template>
  <v-theme-provider light>
    <base-section
      id="about"
      class="accent text-center"
      space="56"
    >
      <base-section-heading
        class="primary--text"
        title="Über Uns"
      />
      <v-container>
        <h3 class=" text-h5 font-weight-bold mb-4 text-center">
          Seit 1985 sind wir Lorem Ipsum Breakdance-Szene in der Schweiz. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.
        </h3>
      </v-container>
      <div class="py-4" />
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNews',
    provide: {
      heading: { align: 'center' },
    },
  }
</script>
